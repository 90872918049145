export function getIsTopUpEnabled({
  countryCode,
  userId,
}: {
  countryCode: string | undefined;
  userId: string | undefined;
}) {
  if (import.meta.env.DEV || import.meta.env.VITE_TEST) {
    return true;
  }
  return countryCode === "NZ";
}
