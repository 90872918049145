import { meQueryOptions } from "@/api/auth";
import { myLocationQueryOptions } from "@/api/me";
import { queryClient } from "@/api/utils";
import { getIsTopUpEnabled } from "@/utils/topup";
import { createRoute, redirect } from "@tanstack/react-router";
import { valibotSearchValidator } from "@tanstack/router-valibot-adapter";
import { fallback, object, optional, string } from "valibot";
import { appRoute } from "../+route";

const searchSchema = object({
  "return-to": fallback(optional(string()), undefined),
});

export const topUpRoute = createRoute({
  path: "top-up",
  beforeLoad: async () => {
    const me = await queryClient.ensureQueryData(meQueryOptions);
    const location = await queryClient.ensureQueryData(myLocationQueryOptions);
    const enabled = getIsTopUpEnabled({
      userId: me.id,
      countryCode: location?.country_code,
    });
    if (!enabled) {
      throw redirect({
        to: "/marketplace",
      });
    }
  },
  validateSearch: valibotSearchValidator(searchSchema),
  getParentRoute: () => appRoute,
});
